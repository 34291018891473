@charset "UTF-8";
#top-nav .shopping_cart {
  display: inline-block;
  float: right;
  padding-top: 0; }

.shopping_cart {
  position: relative;
  display: block;
  float: right;
  padding-top: 10px; }
  .shopping_cart .svg-cart-dims {
    width: 40px;
    height: 40px;
    fill: #666;
    vertical-align: middle; }
  .shopping_cart > a:first-child {
    height: 4.2rem;
    transition: .3s;
    display: inline-block;
    padding: 0px 12px 0px 15px;
    color: #666;
    position: relative; }
    .shopping_cart > a:first-child span {
      font-size: 2rem;
      line-height: 1;
      display: inline-block;
      vertical-align: middle; }
      .shopping_cart > a:first-child span:nth-child(3) {
        padding: 2px 15px 0 15px; }
    .shopping_cart > a:first-child span.ajax_cart_quantity {
      top: -7px;
      right: 7px;
      font-size: 1.4rem;
      border-radius: 2rem;
      display: inline-block;
      background: #E72886;
      min-width: 2.2rem;
      height: 2.2rem;
      position: relative;
      text-align: center;
      padding: 0;
      line-height: 2.2rem;
      color: #fff; }
    .shopping_cart > a:first-child:hover {
      color: #fff;
      transition: .3s;
      background: #E72886; }
      .shopping_cart > a:first-child:hover:after {
        transform: rotate(180deg);
        transition: .3s; }
      .shopping_cart > a:first-child:hover .svg-cart-dims {
        fill: #fff; }
      .shopping_cart > a:first-child:hover span.ajax_cart_quantity {
        background: #444; }
  .shopping_cart._empty > a:first-child:after {
    display: none; }
  .shopping_cart .cart-prices-line {
    padding-bottom: 3px; }

#top-cart-content {
  position: absolute;
  background: #fff;
  z-index: 100;
  display: none;
  opacity: 0;
  top: 5.2rem;
  right: -25px;
  box-shadow: 0 9px 20px rgba(0, 0, 0, 0.4);
  padding: 20px 10px 15px 10px;
  transition: opacity .3s; }
  #top-cart-content._open {
    opacity: 1;
    transition: opacity .3s; }
  #top-cart-content .cart_block_shipping_cost,
  #top-cart-content .cart_block_tax_cost,
  #top-cart-content .cart_block_total,
  #top-cart-content .cart_block_wrapping_cost {
    float: right;
    font-size: 16px; }
  #top-cart-content .cart_block_total {
    font-size: 18px;
    font-weight: bold; }
  #top-cart-content .cart-images {
    float: left;
    width: 80px; }
  #top-cart-content .cart-info {
    float: left;
    width: calc( 100% - 80px);
    padding: 0 15px 0px 5px; }
  #top-cart-content .ajax_cart_block_remove_link {
    position: absolute;
    top: 5px;
    right: -5px;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    text-align: center;
    transition: all 0.3s; }
    #top-cart-content .ajax_cart_block_remove_link:hover {
      background: #777;
      color: #fff;
      transition: all 0.3s; }
  #top-cart-content dt {
    display: block;
    padding: 0 0 10px 0px;
    position: relative;
    font-weight: normal; }
    #top-cart-content dt:before, #top-cart-content dt:after {
      content: " ";
      display: table; }
    #top-cart-content dt:after {
      clear: both; }
  #top-cart-content dl {
    margin: 0 0 15px 0;
    border-bottom: 1px solid #eee; }
  #top-cart-content .cart-prices {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 0 0 15px 0; }
  #top-cart-content table.vouchers {
    clear: both;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px; }
    #top-cart-content table.vouchers tr td {
      padding: 2px; }
      #top-cart-content table.vouchers tr td.quantity {
        margin-right: 5px;
        min-width: 18px; }
      #top-cart-content table.vouchers tr td.delete {
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 15px; }
  #top-cart-content #button_order_cart {
    font-size: 18px;
    padding: 12px 30px; }
  #top-cart-content .cart-buttons {
    text-align: center; }

@media (max-width: 767px) {
  .shopping_cart > a:first-child .title {
    display: none; } }

@media (min-width: 768px) {
  #top-cart-content {
    width: 450px;
    right: 0; } }

@media (min-width: 992px) {
  .shopping_cart > a:first-child:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-family: "FontAwesome";
    float: right;
    font-size: 2rem;
    padding: 0;
    transition: .3s;
    line-height: 2.8rem;
    vertical-align: middle; } }

@media (min-width: 1050px) {
  #block-cart-top {
    padding-top: 13px; } }

.layer_cart_overlay {
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98; }

* html .layer_cart_overlay {
  position: absolute;
  left: 0;
  margin-left: -160px;
  opacity: .20; }

#layer_cart {
  background-color: #fff;
  position: absolute;
  display: none;
  z-index: 99;
  width: 84%;
  margin-right: 8%;
  margin-left: 8%; }
  #layer_cart .layer_cart_product {
    padding: 30px 30px 30px 30px;
    overflow: hidden;
    position: static; }
    #layer_cart .layer_cart_product .title {
      display: block;
      font: 400 23px/29px "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
      margin-bottom: 22px; }
      @media (max-width: 767px) {
        #layer_cart .layer_cart_product .title {
          font-size: 18px;
          padding-right: 0;
          line-height: normal; } }
      #layer_cart .layer_cart_product .title i {
        font-size: 30px;
        line-height: 30px;
        float: left;
        padding-right: 8px; }
        @media (max-width: 767px) {
          #layer_cart .layer_cart_product .title i {
            font-size: 22px;
            line-height: 22px; } }
    #layer_cart .layer_cart_product .product-image-container {
      max-width: 178px;
      border: 1px solid #eee;
      padding: 5px;
      float: left;
      margin-right: 30px; }
      @media (max-width: 480px) {
        #layer_cart .layer_cart_product .product-image-container {
          float: none;
          margin-right: 0;
          margin-bottom: 10px; } }
    #layer_cart .layer_cart_product .layer_cart_product_info {
      padding: 38px 0 0 0; }
      #layer_cart .layer_cart_product .layer_cart_product_info #layer_cart_product_title {
        display: block;
        padding-bottom: 8px; }
      #layer_cart .layer_cart_product .layer_cart_product_info > div {
        padding-bottom: 7px; }
        #layer_cart .layer_cart_product .layer_cart_product_info > div strong {
          padding-right: 3px; }
  #layer_cart #layer_cart_product_price,
  #layer_cart .ajax_block_products_total,
  #layer_cart .ajax_block_cart_total {
    color: #333; }
  #layer_cart .layer_cart_cart {
    background: #fafafa;
    border-left: 1px solid #eee;
    padding: 21px 30px 170px 30px;
    border-radius: 0px 4px 4px 0px;
    position: relative; }
    @media (min-width: 1200px) {
      #layer_cart .layer_cart_cart {
        min-height: 318px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      #layer_cart .layer_cart_cart {
        min-height: 360px; } }
    @media (max-width: 991px) {
      #layer_cart .layer_cart_cart {
        border-radius: 0px 0 4px 4px;
        border-left: none;
        border-top: 1px solid #eee; } }
    #layer_cart .layer_cart_cart .title {
      display: block;
      font: 400 23px/29px "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
      color: #888;
      border-bottom: 1px solid #eee;
      padding-bottom: 13px;
      margin-bottom: 17px; }
      @media (max-width: 767px) {
        #layer_cart .layer_cart_cart .title {
          font-size: 18px; } }
    #layer_cart .layer_cart_cart .layer_cart_row {
      padding: 0 0 7px 0; }
    #layer_cart .layer_cart_cart .button-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0px 30px 20px 30px; }
      #layer_cart .layer_cart_cart .button-container .btn {
        margin-bottom: 10px; }
  #layer_cart .cross {
    position: absolute;
    right: 7px;
    top: 8px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: #888;
    z-index: 2; }
    #layer_cart .cross:before {
      content: "\f057";
      display: block;
      font-family: "FontAwesome";
      font-size: 25px;
      line-height: 25px; }
    #layer_cart .cross:hover {
      color: #0586E2; }
  #layer_cart .continue {
    cursor: pointer; }
  #layer_cart p {
    padding: 0px; }

/************************************************************************
						Layer Cart Caroucel
*************************************************************************/
#layer_cart .crossseling .crossseling-content {
  border-top: 1px solid #eee;
  padding: 26px 30px 40px 30px;
  position: relative; }

#layer_cart .crossseling h2 {
  font: 400 23px/26px "Lato", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #888;
  margin-bottom: 25px; }

#layer_cart .crossseling #blockcart_list {
  max-width: 773px;
  width: 84%;
  margin: 0 auto;
  overflow: hidden; }
  #layer_cart .crossseling #blockcart_list ul {
    display: block; }
    #layer_cart .crossseling #blockcart_list ul li {
      float: left;
      width: 178px;
      margin: 0 0px 0 0; }
      #layer_cart .crossseling #blockcart_list ul li .product-image-container {
        border: 1px solid #eee;
        text-align: center;
        padding: 5px;
        margin-bottom: 15px; }
        #layer_cart .crossseling #blockcart_list ul li .product-image-container img {
          width: 100%; }
      #layer_cart .crossseling #blockcart_list ul li .product-name {
        padding-bottom: 5px; }
        #layer_cart .crossseling #blockcart_list ul li .product-name a {
          font-size: 15px;
          line-height: 20px; }
  #layer_cart .crossseling #blockcart_list a.bx-prev,
  #layer_cart .crossseling #blockcart_list a.bx-next {
    display: block;
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    color: #888;
    text-indent: -5000px;
    position: absolute;
    top: 45%;
    margin-top: -20px; }
    #layer_cart .crossseling #blockcart_list a.bx-prev:after,
    #layer_cart .crossseling #blockcart_list a.bx-next:after {
      display: block;
      text-indent: 0; }
    #layer_cart .crossseling #blockcart_list a.bx-prev:hover,
    #layer_cart .crossseling #blockcart_list a.bx-next:hover {
      color: #c0c0c0; }
    #layer_cart .crossseling #blockcart_list a.bx-prev.disabled,
    #layer_cart .crossseling #blockcart_list a.bx-next.disabled {
      display: none; }
  #layer_cart .crossseling #blockcart_list a.bx-prev {
    left: 5%; }
    #layer_cart .crossseling #blockcart_list a.bx-prev:after {
      content: "\f137"; }
  #layer_cart .crossseling #blockcart_list a.bx-next {
    right: 5%; }
    #layer_cart .crossseling #blockcart_list a.bx-next:after {
      content: "\f138"; }
  #layer_cart .crossseling #blockcart_list .bx-controls {
    overflow: hidden; }
